import React from 'react'
import './Portfolio.css'

function Portfolio() {
  return (
    <div className="portfolio-text-div" id="projects">
      <h1 className="portfolio-text">Portfolio Work</h1>
    </div>
  )
}

export default Portfolio
